import React, {Fragment, useEffect, useState} from 'react';
import Switcher from "./components/Switcher";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

/*
* @ Component Imported
* */
import TeamPage from "./pages/team";
import AboutPage from "./pages/about";
import ErrorPage from "./pages/404Error";
import ContactPage from "./pages/contact";
import ServicePage from "./pages/service";
import HomeBlog from "./pages/home/HomeBlog";
import HomeDefault from "./pages/home/HomeDefault";
import BlogDetailsPage from "./pages/blog/BlogDetails";
import HomeFiveColumn from './pages/home/HomeFiveColumn';
import HomeFourColumn from "./pages/home/HomeFourColumn";
import BlogThreeColumnPage from "./pages/blog/ThreeColumn";
import HomeThreeColumn from "./pages/home/HomeThreeColumn";
import PortfolioDetailsPage from "./pages/portfolio/details";
import HomePersonalPortfolio from "./pages/home/HomePersonalPortfolio";
import HomeFreelancerPortfolio from "./pages/home/HomeFreelancerPortfolio";
import PortfolioGridTwoColumnPage from "./pages/portfolio/grid/two-column";
import BlogTwoColumnLeftSidebarPage from "./pages/blog/TwoColumnLeftSidebar";
import PortfolioGridFourColumnPage from "./pages/portfolio/grid/four-column";
import PortfolioGridThreeColumnPage from "./pages/portfolio/grid/three-column";
import BlogTwoColumnRightSidebarPage from "./pages/blog/TwoColumnRightSidebar";
import PortfolioMasonryTwoColumnPage from "./pages/portfolio/masonry/two-column";
import PortfolioMasonryFourColumnPage from "./pages/portfolio/masonry/four-column";
import PortfolioMasonryThreeColumnPage from "./pages/portfolio/masonry/three-column";
import PortfolioGridFourColumnFullWidthPage from "./pages/portfolio/grid/four-column-fullwidth";
import PortfolioGridFiveColumnFullWidthPage from "./pages/portfolio/grid/five-column-fullwidth";
import PortfolioGridThreeColumnFullWidthPage from "./pages/portfolio/grid/three-column-fullwidth";
import PortfolioMasonryFourColumnFullWidthPage from "./pages/portfolio/masonry/four-column-fullwidth";
import PortfolioMasonryFiveColumnFullWidthPage from "./pages/portfolio/masonry/five-column-fullwidth";
import PortfolioMasonryThreeColumnFullWidthPage from "./pages/portfolio/masonry/three-column-fullwidth";
import HomeClean from './pages/home/HomeClean';
import Memberships from './pages/memberships/Memberships';
import Academy from './pages/academy/Academy';
import Instructors from './pages/instructors/Instructors';
import Programs from './pages/programs/Programs';
import Kids from './pages/kids/Kids';
import Nogi from './pages/no-gi/No-gi';
import Gi from './pages/gi/Gi';
import Loading from './components/loading';
import ProgramDetailsPage from './pages/ProgramDetails/details';
import Scrollbar from 'perfect-scrollbar-react';
import 'perfect-scrollbar-react/dist/style.min.css';
import Schedule from './pages/schedule';
import FreeTrial from './pages/trial/FreeTrial';
import GuestPass from './pages/guestpass/GuestPass';
import Account from './pages/account/Account';

const App = () => {
       const [loading, setLoading] = useState(true);
       const isMobile = window.innerWidth < 992;

       useEffect(() => {
              const handleLoad = () => setTimeout(() => { setLoading(false); }, 0);
              const handleError = () => setLoading(false);
          
              // Check if document is already ready
              if (document.readyState === 'complete' || document.readyState === 'interactive') {
                  handleLoad();
              } else {
                  document.addEventListener('DOMContentLoaded', handleLoad);
                  window.addEventListener('load', handleLoad);
                  window.addEventListener('error', handleError);
              }
          
              return () => {
                  document.removeEventListener('DOMContentLoaded', handleLoad);
                  window.removeEventListener('load', handleLoad);
                  window.removeEventListener('error', handleError);
              };
          }, []);
          
          
   
       if (loading) {
           return <Loading />;
       }

    return (
        <Fragment>
            {/* <Switcher/> */}
            <Router>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`}
                           component={ContactPage}/>
                     <Route path={`${process.env.PUBLIC_URL + "/atlanta-jiu-jitsu"}`}
                           component={ContactPage}/>
                     <Route path={`${process.env.PUBLIC_URL + "/marietta-jiu-jitsu"}`}
                           component={ContactPage}/>
                     <Route path={`${process.env.PUBLIC_URL + "/atlanta-bjj"}`}
                           component={ContactPage}/>
                     <Route path={`${process.env.PUBLIC_URL + "/marietta-bjj"}`}
                           component={ContactPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/team"}`}
                           component={TeamPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/service"}`}
                           component={ServicePage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog/:slug"}`}
                           component={BlogDetailsPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-two-column-left-sidebar"}`}
                           component={BlogTwoColumnLeftSidebarPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog-two-column-right-sidebar"}`}
                           component={BlogTwoColumnRightSidebarPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/blog"}`}
                           component={BlogThreeColumnPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio/:slug"}`}
                           component={PortfolioDetailsPage}/>
                     <Route path={`${process.env.PUBLIC_URL + "/instructors/:slug"}`}
                           component={PortfolioDetailsPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-masonry-five-column-fullwidth"}`}
                           component={PortfolioMasonryFiveColumnFullWidthPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-masonry-four-column-fullwidth"}`}
                           component={PortfolioMasonryFourColumnFullWidthPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-masonry-three-column-fullwidth"}`}
                           component={PortfolioMasonryThreeColumnFullWidthPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-masonry-four-column"}`}
                           component={PortfolioMasonryFourColumnPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-masonry-three-column"}`}
                           component={PortfolioMasonryThreeColumnPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-masonry-two-column"}`}
                           component={PortfolioMasonryTwoColumnPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-grid-five-column-fullwidth"}`}
                           component={PortfolioGridFiveColumnFullWidthPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-grid-four-column-fullwidth"}`}
                           component={PortfolioGridFourColumnFullWidthPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-grid-three-column-fullwidth"}`}
                           component={PortfolioGridThreeColumnFullWidthPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-grid-four-column"}`}
                           component={PortfolioGridFourColumnPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-grid-three-column"}`}
                           component={PortfolioGridThreeColumnPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/portfolio-grid-two-column"}`}
                           component={PortfolioGridTwoColumnPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/about"}`}
                           component={AboutPage}/>
                    <Route path={`${process.env.PUBLIC_URL + "/home-freelancer-portfolio"}`}
                           component={HomeFreelancerPortfolio}/>
                    <Route path={`${process.env.PUBLIC_URL + "/home-clean"}`}
                           component={HomeClean}/>
                    <Route path={`${process.env.PUBLIC_URL + "/home-personal-portfolio"}`}
                           component={HomePersonalPortfolio}/>
                    <Route path={`${process.env.PUBLIC_URL + "/home-blog"}`}
                           component={HomeBlog}/>
                    <Route path={`${process.env.PUBLIC_URL + "/home-five-column"}`}
                           component={HomeFiveColumn}/>
                    <Route path={`${process.env.PUBLIC_URL + "/home-four-column"}`}
                           component={HomeFourColumn}/>
                    <Route path={`${process.env.PUBLIC_URL + "/home-three-column"}`}
                           component={HomeThreeColumn}/>
                    <Route path={`${process.env.PUBLIC_URL + "/home-default"}`}
                           component={HomeDefault}/>
                     <Route path={`${process.env.PUBLIC_URL + "/memberships"}`}
                           component={Memberships}/>
                     <Route path={`${process.env.PUBLIC_URL + "/academy"}`}
                           component={Academy}/>
                     <Route path={`${process.env.PUBLIC_URL + "/instructors"}`}
                           component={Instructors}/>
                     <Route path={`${process.env.PUBLIC_URL + "/program/:slug"}`}
                            component={ProgramDetailsPage}/>
                     <Route path={`${process.env.PUBLIC_URL + "/programs"}`}
                            component={Programs}/>
                     <Route path={`${process.env.PUBLIC_URL + "/gi"}`}
                            component={Gi}/>
                     <Route path={`${process.env.PUBLIC_URL + "/no-gi"}`}
                            component={Nogi}/>
                     <Route path={`${process.env.PUBLIC_URL + "/kids"}`}
                            component={Kids}/>
                     <Route path={`${process.env.PUBLIC_URL + "/schedule"}`}
                            component={Schedule}/>
                     <Route path={`${process.env.PUBLIC_URL + "/free-trial"}`}
                            component={FreeTrial}/>
                     <Route path={`${process.env.PUBLIC_URL + "/guest-pass"}`}
                            component={GuestPass}/>
                     <Route path={`${process.env.PUBLIC_URL + "/account"}`}
                            component={Account}/>
                     <Route path={`${process.env.PUBLIC_URL + ""}`}
                           component={HomeClean}/>
                    <Route exact component={ErrorPage}/>
                </Switch>
            </Router>
        </Fragment>
    );
};

export default App;